import { useEffect, useState } from "react";

import { filter, find, isEmpty, isNil, map } from "lodash";
import UserStore from "../../Common/Users/UserStore";

const useUserPermissions = (desiredPermission = undefined) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (!UserStore.securityAccess) return;

    const permissions = map(UserStore.securityAccess, (permission, permissionKey) => ({
      key: filter(permissionKey.split("permission:"), v => !isEmpty(v))[0],
      permission
    }));

    if (!isNil(desiredPermission)) {
      const permission = find(permissions, perm => perm.key === desiredPermission);

      if (!isNil(permission)) return setPermissions(permission.permission);
      return setPermissions(undefined);
    }
    setPermissions(permissions);
  }, [UserStore.securityAccess]);

  return permissions;
};

export default useUserPermissions;
