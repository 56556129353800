import React, { useEffect, useState } from "react";
import { Button, Flex } from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import WrapperSubHeader from "./WrapperSubHeader";
import PageNavigation from "./PageNavigation";
import BackendService from "../../BackendService";
import ViewGeneratorHeader from "../../../ViewGenerator/components/ViewGeneratorHeader";

import BookmarksStore from "../../Bookmarks/BookmarksStore";

import useDynamicSections from "../hooks/useDynamicSections";
import useCurrentRoute from "../hooks/useCurrentRoute";
import useUserPermissions from "../../../Settings/hooks/useUserPermissions";
import UserStore from "../../Users/UserStore";

const SubHeader = ({ pageTitle, currentNameTitle, t }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const organizationMngtPermission = useUserPermissions("organizationMgmt");

  const { path } = match;

  const subHeaderSections = useDynamicSections(path, "subHeader");

  const { showBookmarks } = BookmarksStore;

  const [dynamicComponentsExist, setDynamicComponentsExist] = useState(false);

  const currentRoute = useCurrentRoute(path);

  const navigateToOrganizationMngt = () => history.push("/settings");

  useEffect(() => {
    BookmarksStore.init(match.params.projectName);
    if (match?.params?.projectName) BookmarksStore.init(match.params.projectName);
  }, [match]);

  useEffect(() => {
    if (currentRoute?.handle?.dynamicComponents) {
      setDynamicComponentsExist(true);
    }
  }, [currentRoute]);

  return (
    <>
      <span style={{ marginTop: showBookmarks && 40 }}>
        <WrapperSubHeader path={path}>
          <PageNavigation pageTitle={pageTitle} currentNameTitle={currentNameTitle} path={path} />
          <Flex style={{ marginRight: 24 }}>
            {dynamicComponentsExist &&
              subHeaderSections.map((Component, index) => <Component key={index} match={match} />)}
            <ViewGeneratorHeader />

            {path === "/utils/project/:subpage?/:tab?" &&
              (organizationMngtPermission === "view" || UserStore?.user?.version === -1) && (
                <Button onClick={navigateToOrganizationMngt}>{t("Settings.organizationMgmt")}</Button>
              )}
          </Flex>
        </WrapperSubHeader>
      </span>
    </>
  );
};

export default withTranslation()(observer(SubHeader));
