import { message } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import i18next from "i18next";

import GraphQlService from "../../Common/GraphQlService";

class ProjectStore {
  graphQlService = new GraphQlService();

  isLoading = false;
  isLoadingMenu = "";
  project = undefined;
  projects = [];
  jobs = [];
  tenant = {};
  settingsProjectId = "";

  assetsDefinitions = [];

  assignSubcontractorFormVisible = false;
  assignSubcontractorData = { symbol: undefined, value: undefined, projectId: undefined };

  assignProjectFormVisible = false;
  assignProjectName = undefined;

  defaultTab = "general";

  userProjectConfig = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setProjectId = id => {
    this.settingsProjectId = id;
  };
  deleteProject = async id => {
    try {
      const response = await this.graphQlService.post(
        `mutation {
          projectDelete(projectId:"${id}")
        }`
      );
      return response.data.projectDelete;
    } catch (error) {
      return false;
    }
  };

  load = (id, loadTenant = true) => {
    // this.isLoading = true;
    this.graphQlService
      .get(
        `{ ${
          id ? `project(id: "${id}")` : `project`
        } {  id value tenantId menu riskStatuses procore mailboxConfig payload subcontractors { id symbol value } users { securityGroup { id symbol } branchAccess { id symbol } subcontractor { id symbol } user { id email username } projectUserConfig { collectCreatorOnlySubmissions employeeIds } } } }`
      )
      .then(r => {
        var project = cloneDeep(r.data.project);

        if (!project.subcontractors) project.subcontractors = [];
        if (!project.users) project.users = [];
        project.procore = JSON.parse(project.procore);
        project.users = project.users.filter(x => x.user != null);
        project.payload = JSON.parse(project.payload || "{}");
        project.mailboxConfig = JSON.parse(project.mailboxConfig || "{}");
        project.menu = isEmpty(JSON.parse(project.menu)) ? [] : JSON.parse(project.menu);
        this.project = project;

        if (id)
          this.graphQlService
            .get(`{ projectJobs(projectId: "${id}") { key label isEnabled } }`)
            .then(r => {
              if (r.data) this.jobs = r.data.projectJobs;
            })
            .catch(err => {
              console.log("HERE 1", err);
            });

        this.graphQlService
          .get(`{ assetsDefinitions(projectId: "${this.project.id}") { id symbol value isVirtual isEvidence } }`)
          .then(r => (this.assetsDefinitions = r.data.assetsDefinitions.filter(x => !x.isVirtual && !x.isEvidence)))
          .catch(err => {
            consle.log("HERE 2", err);
          });

        if (loadTenant === true) {
          this.graphQlService
            .get(
              `{ tenant(id: "${this.project.tenantId}") { authType branches { id symbol } securityGroup { id symbol } } }`
            )
            .then(r => {
              var tenant = cloneDeep(r.data.tenant);
              if (!tenant.branches) tenant.branches = [];
              if (!tenant.securityGroup) tenant.securityGroup = [];
              this.tenant = tenant;
              // this.isLoading = false;
            })
            .catch(err => {
              console.log("HERE 3", err);
            });
        } else this.isLoading = false;
      })
      .catch(err => {
        console.log("MAIN ERR", err);
      });
  };

  loadProjects = () => {
    this.isLoading = true;
    this.graphQlService
      .get(`{ tenant(id: "${localStorage.getItem(`tenantId`)}") { projects { id value } } }`)
      .then(r => {
        this.projects = r.data.tenant.projects;
        this.isLoading = false;
      });
  };

  // = = = Projects
  createProject = () => {
    var tenantId = this.tenant.id;
    if (!tenantId) tenantId = localStorage.getItem(`tenantId`);

    this.isLoading = true;
    this.graphQlService
      .post(`mutation post($data: String, $tenantId: Guid) { projectCreate(name: $data, tenantId: $tenantId) }`, {
        data: this.assignProjectName,
        tenantId: tenantId
      })
      .then(r => {
        this.assignProjectName = undefined;
        this.assignProjectFormVisible = false;
        this.loadProjects();
      });
  };
  updateMenu = async () => {
    this.isLoadingMenu = "";
    try {
      const response = await this.graphQlService.post(
        `mutation mutate($data: UpdateProjectCommand){ projectUpdate(data: $data) }`,
        {
          data: {
            projectId: this.project.id,
            name: this.project.value,
            menu: JSON.stringify(this.project.menu)
          }
        }
      );
      const error = await response?.error;
      if (!error) {
        message.success(i18next.t("Settings.messageNavigation.updated"));

        this.isLoadingMenu = "success";
        return "success";
      }
    } catch (error) {
      message.error(i18next.t("Settings.messageNavigation.error"));
      this.isLoadingMenu = "error";
      return "error";
    }
  };
  update = () => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: UpdateProjectCommand){ projectUpdate(data: $data) }`, {
        data: {
          projectId: this.project.id,
          name: this.project.value,
          primaryAssets: this.project.payload["PrimaryAssets"],
          riskStatuses: this.project.payload["RiskStatuses"],
          nasIp: this.project.payload["NasIp"]
        }
      })
      .then(() => {
        message.success(`Config updated!`);
        this.isLoading = false;
      });
  };

  updateAsync = () => {
    return this.graphQlService
      .post(`mutation mutate($data: UpdateProjectCommand){ projectUpdate(data: $data) }`, {
        data: {
          projectId: this.project.id,
          primaryAssets: this.project.payload["PrimaryAssets"],
          riskStatuses: this.project.payload["RiskStatuses"],
          nasIp: this.project.payload["NasIp"]
        }
      })
      .then(() => {
        message.success(`Config updated!`);
      });
  };

  updateUserConfig = () => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: UpdateProjectUserConfigCommand){ projectUserConfigUpdate(data: $data) }`, {
        data: {
          targetUserId: this.userProjectConfig.user.id,
          projectId: this.project.id,
          collectCreatorOnlySubmissions: this.userProjectConfig.projectUserConfig.collectCreatorOnlySubmissions,
          employeeIds: this.userProjectConfig.projectUserConfig.employeeIds
        }
      })
      .then(() => {
        message.success(`Config updated!`);
        this.userProjectConfig = undefined;
        this.isLoading = false;
      });
  };

  createSubcontractor = () => {
    this.isLoading = true;
    this.assignSubcontractorData.projectId = this.project.id;
    this.graphQlService
      .post(`mutation post($data: CreateSubContractorCommand) { subcontractorCreate(data: $data) }`, {
        data: this.assignSubcontractorData
      })
      .then(r => {
        this.assignSubcontractorData = {};
        this.assignSubcontractorFormVisible = false;
        this.load(this.project.id);
      });
  };

  assignBranch = (userId, targetId) => {
    this.isLoading = true;
    this.graphQlService
      .post(
        `mutation post($objectId: Guid, $userId: Guid, $projectId: Guid) { branchAssignUser(objectId: $objectId, userId: $userId, projectId: $projectId) }`,
        { projectId: this.project.id, objectId: targetId, userId: userId }
      )
      .then(r => {
        this.load(this.project.id, false);
      });
  };

  assignSubcontractor = (userId, targetId) => {
    this.isLoading = true;
    this.graphQlService
      .post(
        `mutation post($objectId: Guid, $userId: Guid, $projectId: Guid) { subcontractorAssignToUser(subcontractorId: $objectId, userId: $userId, projectId: $projectId) }`,
        { projectId: this.project.id, objectId: targetId, userId: userId }
      )
      .then(r => {
        this.load(this.project.id, false);
      });
  };

  assignSecurityGroup = (userId, targetId) => {
    this.isLoading = true;
    this.graphQlService
      .post(
        `mutation post($objectId: Guid, $userId: Guid, $projectId: Guid) { securityGroupAssignUser(objectId: $objectId, userId: $userId, projectId: $projectId) }`,
        { projectId: this.project.id, objectId: targetId, userId: userId }
      )
      .then(r => {
        this.load(this.project.id, false);
      });
  };

  toggleJob = (jobKey, status) => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation post($data: UpdateProjectJobCommand) { projectJobsUpdate(data: $data) }`, {
        data: { projectId: this.project.id, jobKey: jobKey, isEnabled: status }
      })
      .then(r => {
        message.success(`Job has been modified`);
        this.load(this.project.id);
      });
  };

  updateProcoreConfig = () => {
    this.graphQlService.post(
      `mutation post($data: UpdateProjectProcoreCommand) { projectUpdateProcore(data: $data) }`,
      {
        data: {
          projectId: this.project.id,
          procoreProjectId: this.project.procore.ProjectId,
          procoreCompanyId: this.project.procore.CompanyId
        }
      }
    );
  };

  clearData = () => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation post($data: Guid) { jobProjectClearData(projectId: $data) }`, { data: this.project.id })
      .then(r => {
        message.success(`Project data has been cleared!`);
        this.isLoading = false;
      });
  };

  initDemoData = () => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation post($data: Guid) { jobProjectInitDemoData(projectId: $data) }`, { data: this.project.id })
      .then(r => {
        message.success(`Project data will be generated soon!`);
        this.isLoading = false;
      });
  };

  runJob = key => {
    this.isLoading = true;
    this.graphQlService
      .post(
        `mutation post($projectId: Guid, $jobKey: String) { jobProjectExecute(projectId: $projectId, jobKey: $jobKey) }`,
        { projectId: this.project.id, jobKey: key }
      )
      .then(() => {
        message.success(`Job has been started!`);
        this.isLoading = false;
      });
  };
}

export default new ProjectStore();
