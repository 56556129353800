import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { withTranslation } from "react-i18next";
import InputMask from "react-input-mask";

const FormInput = props => {
  const [validationError, setValidationError] = useState(undefined);
  const [displayValue, setDisplayValue] = useState("");
  const [rawValue, setRawValue] = useState("");

  const handleChange = e => {
    const valueWithMask = e.target.value;
    const valueWithoutMask = valueWithMask.replace(/\D/g, "");

    setDisplayValue(valueWithMask);
    setRawValue(valueWithoutMask);
  };

  const handleBlur = () => {
    if (props.updateValue) {
      props.updateValue(props.id, rawValue);
    }
  };
  useEffect(() => {
    setDisplayValue(props.storeValue !== undefined ? props.storeValue : props.value || "");

    validate();
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
    validate();
  }, [props.value]);

  useEffect(() => {
    validate();
  }, [displayValue]);

  const validate = () => {
    if (!props.identifier || !props.isSubmission) return;

    setValidationError(undefined);
    if (!displayValue || displayValue === "") {
      setValidationError({ status: "error", text: props.t("collect.validations.missingValue") });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else {
      setValidationError({ status: undefined, text: "" });
      if (props.setValidityStatus) props.setValidityStatus(props.id, true);
    }
  };
  const validateMask = (mask, inputMode) => {
    const validMaskChars = inputMode ? /^[9a-zA-Z\*\+\-\/\(\)\.\:\s]*$/ : /^[9a\*\+\-\/\(\)\.\:\s]*$/;
    if (!mask || !validMaskChars.test(mask)) {
      return null;
    }

    return mask;
  };
  const isNumericMask = mask => {
    const regex = /^[\d\*\+\-\/\(\)\.\:\s]*$/;
    return regex.test(mask);
  };
  const mask = validateMask(props.mask, props.inputMode);
  return (
    <>
      <Form.Item
        name={props.label}
        label={
          <span>
            {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
          </span>
        }
        disabled={props.disabled}
        style={{ margin: "15px 0px" }}
        validateStatus={validationError?.status}
        hasFeedback
        help={validationError?.text}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <InputMask
            placeholder={props.placeholder}
            key={props.id}
            mask={mask}
            value={displayValue}
            maskChar={null}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={props.disabled}
            inputMode={props.inputMode || (!props.inputMode && isNumericMask(props.mask)) ? "numeric" : "text"}
          >
            {inputProps => (
              <Input {...inputProps} style={{ marginRight: 20 }} disableUnderline disabled={props.disabled} />
            )}
          </InputMask>
        </div>
      </Form.Item>
    </>
  );
};

export default withTranslation()(FormInput);
