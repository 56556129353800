import { Col, Form, Row, Select, Spin } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import { withTranslation } from "react-i18next";
import { isUndefined, isEmpty, get, set } from "lodash";
import GraphQlService from "../../../Common/GraphQlService";
import FormSubmissionStore from "../../Submissions/FormSubmissionStore";
import { Selector } from "antd-mobile";
import { Form as FormMobile, Input as InputMobile } from "antd-mobile";

const FormSelect = props => {
  const [validationError, setValidationError] = useState(undefined);
  const [items, setItems] = useState(undefined);
  const currentPlafrom = localStorage.getItem("platform");

  const [value, setValue] = useState(undefined);

  useEffect(() => {
    initItems();
  }, []);

  useEffect(() => {
    validate();
  }, [value]);

  const initItems = async () => {
    let definedItems = [];

    if (!props.itemsSource || props.itemsSource === "user") {
      definedItems = props.items;
    } else if (props.itemsSource === "data") {
      const graphQlService = new GraphQlService();
      const r = JSON.parse(
        (
          await graphQlService.get(
            `{ containerValuesByAssetDefinition(id: "${props.externalItemsSourceConfig?.assetDefinition?.id}") }`
          )
        ).data.containerValuesByAssetDefinition || "[]"
      );
      definedItems = r
        .filter(x => x.fieldId === props.externalItemsSourceConfig?.assetDefinitionField?.id)
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
        .map(x => ({ value: x.id, label: x.label }));

      if (!FormSubmissionStore.externalDataValues[props.externalItemsSourceConfig?.assetDefinition?.id])
        FormSubmissionStore.externalDataValues[props.externalItemsSourceConfig?.assetDefinition?.id] = r;
    }

    setItems(definedItems);
    await initValue(definedItems);
  };

  const initValue = async items => {
    let valueToSet = undefined;
    if (props.storeValue) {
      // some value was set
      if (Object.prototype.toString.call(props.storeValue) === "[object Object]") valueToSet = props.storeValue.value;
      else if (Object.prototype.toString.call(props.storeValue) === "[object Array]" && props.storeValue.length > 0) {
        if (Object.prototype.toString.call(props.storeValue[0]) === "[object Object]")
          valueToSet = props.storeValue.map(sv => sv.value);
        else valueToSet = props.storeValue;
      } else valueToSet = props.storeValue;
      setValue(valueToSet);
    } else {
      if (items.length === 1) {
        // only 1 option
        //if (props.mode && props.mode === "multiple") valueToSet = items.map(x => x.value);
        //else
        valueToSet = items[0];
        setValue(valueToSet);
        if (props.updateValue) {
          props.updateValue(props.id, valueToSet);
        }
      }
    }
  };

  const getMobileValue = () => {
    if (!value) return [];

    if (Array.isArray(value)) {
      if (value.length === 1) return [value[0].key ?? value[0].value ?? value[0]];
      return value.map(v => v.key ?? v.value ?? v);
    }

    return [value.key ?? value.value ?? value];
  };

  const validate = () => {
    if (!props.identifier) return;

    setValidationError(undefined);

    if (isUndefined(value) || isEmpty(value)) {
      setValidationError({ status: "error", text: props.t("collect.validations.missingValue") });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else {
      setValidationError(undefined);
      if (props.setValidityStatus) props.setValidityStatus(props.id, true);
    }
  };
  if (items === undefined || items === null) return <Spin />;
  if (currentPlafrom === "mobile" && !props.allowSearch) {
    const setValueForMobile = getMobileValue();
    return (
      <FormMobile.Item
        name={props.label}
        label={
          <>
            {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
          </>
        }
      >
        <Selector
          key={props.id}
          disabled={props.disabled}
          allowClear
          mode={props.mode ? props.mode : undefined}
          placeholder={props.placeholder}
          onChange={value => {
            if (!props.updateValue) return;

            if (props.externalItemsSourceConfig) {
              if (props.mode || props.mode === "multiple") {
                props.updateValue(
                  props.id,
                  value.map(v => ({ value: v, label: items.find(i => i.value === v)?.label }))
                );
              } else {
                props.updateValue(props.id, { value: value[0], label: items.find(i => i.value === value[0])?.label });
              }
            } else {
              if (props.mode || props.mode === "multiple") {
                props.updateValue(props.id, value);
              } else {
                props.updateValue(props.id, value[0]);
              }
            }
          }}
          autoFocus={true}
          options={items}
          defaultValue={setValueForMobile}
        />
      </FormMobile.Item>
    );
  }

  return (
    <Form.Item
      name={props.id}
      // name={props.label}
      label={
        <>
          {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
        </>
      }
      hasFeedback={true}
      validateStatus={validationError?.status}
      help={validationError?.text}
      style={{ margin: "15px 20px 15px 0px" }}
    >
      <Select
        key={props.id}
        disabled={props.disabled}
        allowClear
        showSearch={true}
        filterOption={(input, option) => {
          setValue(undefined);
          return (option?.children ?? "").toLowerCase().includes(input.toLowerCase());
        }}
        defaultValue={value}
        value={value}
        mode={props.mode ? props.mode : undefined}
        placeholder={props.placeholder}
        onChange={value => {
          //props.updateValue && props.updateValue(props.id, e);

          if (!props.updateValue) return;

          if (props.externalItemsSourceConfig) {
            if (props.mode || props.mode === "multiple") {
              props.updateValue(
                props.id,
                value.map(v => ({ value: v, label: items.find(i => i.value === v)?.label }))
              );
            } else {
              props.updateValue(props.id, { value: value, label: items.find(i => i.value === value)?.label });
            }
          } else {
            if (props.mode || props.mode === "multiple") {
              props.updateValue(
                props.id,
                value.map(v => ({ value: v, label: items.find(i => i.value === v)?.label }))
              );
            } else {
              props.updateValue(props.id, value);
            }
          }
        }}
        optionFilterProp="label"
        autoFocus={true}
        style={{ marginRight: 20 }}
      >
        {items &&
          items.map(item => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export const CollectFormSelectSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };

  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.mode")}
        </Col>
        <Col span={18}>
          <Select
            size="small"
            style={inputStyle}
            value={FormBuilderStore.modifyInputColumn.mode}
            onChange={e => props.setFieldValue("mode", e)}
          >
            <Select.Option value={undefined}>{props.t("collect.single")}</Select.Option>
            <Select.Option value="multiple">{props.t("collect.multiple")}</Select.Option>
          </Select>
        </Col>
      </Row>
    </>
  );
});

export default withTranslation()(FormSelect);
