import React from "react";
import { makeAutoObservable, toJS } from "mobx";
import i18n from "i18next";

import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import FormBuilderDropZone from "./form/inputs/FormBuilderDropZone";

import FormInput from "./../common/form-components/FormInput";
import FormTextArea from "./../common/form-components/FormTextArea";
import FormSelect from "./../common/form-components/FormSelect";
import FormCheckbox from "./../common/form-components/FormCheckbox";
import FormCheckboxGroup from "./../common/form-components/FormCheckboxGroup";
import FormRadio from "./../common/form-components/FormRadio";
import FormFileUpload from "./../common/form-components/FormFileUpload";
import FormFileUploadMobile from "../common/form-components/FormFileUploadMobile";
import FormCamera from "./../common/form-components/FormCamera";
import FormOpenLayersMap from "./../common/form-components/FormOpenLayersMap";

import GraphQlService from "./../../Common/GraphQlService";
import ConfigStore from "../../Common/ConfigStore";
import { message } from "antd";
import FormDateTime from "../common/form-components/FormDateTime";
import FormInputMobile from "../common/form-components/FormInputMobile";
import FormCheckboxMobile from "../common/form-components/FormCheckboxMobile";
import FormCheckboxGroupMobile from "../common/form-components/FormCheckboxGroupMobile";
import FormSwitch from "../common/form-components/FormSwitch";
import FormStarRate from "../common/form-components/FormStarRate";
import FormStarRateMobile from "../common/form-components/FormStarRateMobile";
import FormTitle from "../common/form-components/FormTitle";
import CollectFormDxfEditor from "../common/form-components/CollectFormDxfEditor";
import FormNumericInput from "../common/form-components/FormNumericInput";
import FormSign from "../common/form-components/FormSign";
import CollectFormTable from "../common/form-components/CollectFormTable";
import CollectFormCollapse from "../common/form-components/CollectFormCollapse";
import CollectFormParentView from "../common/form-components/CollectFormParentView";
import CollectFormPdfAnnotatorClaim from "../common/form-components/CollectFormPdfAnnotatorClaim";
import CollectFormText from "../common/form-components/CollectFormText";
import CollectFormForEachComponent from "../common/form-components/CollectFormForEachComponent";
import FormImageAnnotator from "../common/form-components/FormImageAnnotator";
import FormInputMask from "../common/form-components/FormInputMask";
class FormBuilderStore {
  graphQlService = new GraphQlService();

  isLoading = false;

  forms = [];
  pendingForms = [];

  templateId = undefined;
  name = undefined;
  platform = "web";
  isActive = true;
  allowStandalone = true;
  hubOnly = false;
  structure = undefined;
  childTemplateId = undefined;
  createModalVisible = false;
  modifyRowNumber = undefined;
  modifyInputColumn = undefined;
  visibleModal = false;
  // visibleEditRowDrawer=false;
  currentPlatform = localStorage.getItem("platform");

  childTemplates = undefined;

  moveObjectId = undefined;

  assetDefinition = undefined;
  assetDefinitionRelations = [];

  constructor() {
    makeAutoObservable(this);
  }
  // = Change this
  modifyRow = number => (this.modifyRowNumber = number);

  // = = = = = = = = = = Structure helpers
  structureGetRow = (parentId, rowIndex) => {
    var row = undefined;
    if (!parentId) row = this.structure.rows[rowIndex];
    else {
      var element = this.extractElementFromStructure(parentId);
      if (element && element.structure) row = element.structure.rows[rowIndex];
    }
    return row;
  };

  structureGetColumn = (parentId, rowIndex, colIndex) => {
    var col = undefined;
    if (!parentId) col = this.structure.rows[rowIndex].cols[colIndex];
    else {
      var element = this.extractElementFromStructure(parentId);
      col = element.structure.rows[rowIndex].cols[colIndex];
    }
    return col;
  };

  extractElementFromStructure = (id, structure) => {
    var result = undefined;
    if (!structure) structure = this.structure;

    for (let rowIndex = 0; rowIndex < structure.rows.length; rowIndex++) {
      var row = structure.rows[rowIndex];
      for (let colIndex = 0; colIndex < row.cols.length; colIndex++) {
        var col = row.cols[colIndex];
        if (col.id === id) result = col;

        if (!result && col.items) {
          for (let itemIndex = 0; itemIndex < col.items.length; itemIndex++) {
            var item = col.items[itemIndex];

            if (item.id === id) result = item;

            if (!result && item.structure)
              // Check inside for example collapse child
              result = this.extractElementFromStructure(id, item.structure);
          }
        }
      }
    }
    return result;
  };

  // = = = = = = = = = = Structure functions
  addNewRow = (parentId, index) => {
    if (!parentId) {
      if (index === undefined) this.structure.rows.push({ cols: [{ span: 24 }] });
      else this.structure.rows.splice(index + 1, 0, { cols: [{ span: 24 }] });
    } else {
      var element = this.extractElementFromStructure(parentId);
      if (!element.structure) element.structure = { rows: [] };
      if (index === undefined) element.structure.rows.push({ cols: [{ span: 24, parentId: element.id }] });
      else element.structure.rows.splice(index + 1, 0, { cols: [{ span: 24, parentId: element.id }] });
    }
  };

  removeRow = (parentId, rowIndex) => {
    if (!parentId) this.structure.rows = this.structure.rows.filter((data, idx) => idx !== rowIndex);
    else {
      var element = this.extractElementFromStructure(parentId);
      if (!element.structure) element.structure = { rows: [] };
      element.structure.rows = element.structure.rows.filter((data, idx) => idx !== rowIndex);
    }
  };

  addNewColumn = (parentId, rowIndex) => {
    var row = this.structureGetRow(parentId, rowIndex);

    if (row.cols.length >= 3) return;
    var colSpan = 24 / (row.cols.length + 1);
    row.cols.push({ span: colSpan, parentId: parentId });
    for (let col of row.cols) col.span = colSpan;
  };

  removeColumn = (parentId, rowIndex, colIndex) => {
    var row = this.structureGetRow(parentId, rowIndex);

    if (row.cols.length === 1) return;
    else {
      var colSpan = 24 / (row.cols.length - 1);
      row.cols.splice(colIndex, 1);
      for (let col of row.cols) col.span = colSpan;
    }
  };

  addNewInput = (parentId, type, label, items, rowIndex, colIndex) => {
    var col = this.structureGetColumn(parentId, rowIndex, colIndex);
    col.id = uuidv4();
    col.type = type;
    col.label = label;
    if (items) col.items = items;
  };

  clearInput = (parentId, rowIndex, colIndex) => {
    var row = this.structureGetRow(parentId, rowIndex);
    row.cols[colIndex] = { span: row.cols[colIndex].span, parentId: row.cols[colIndex].parentId };
  };

  moveInput = (from, to) => {
    var sourceRow = _.cloneDeep(this.structureGetRow(from[0], from[1]));
    var source = _.clone(sourceRow.cols[from[2]]);

    var targetRow = _.cloneDeep(this.structureGetRow(to[0], to[1]));
    var target = _.clone(targetRow.cols[to[2]]);

    var newTargetRow = this.structureGetRow(to[0], to[1]);
    newTargetRow.cols[to[2]] = { ...sourceRow.cols[from[2]], span: target.span, parentId: target.parentId };

    var newSourceRow = this.structureGetRow(from[0], from[1]);
    newSourceRow.cols[from[2]] = { ...targetRow.cols[to[2]], span: source.span, parentId: source.parentId };

    this.structure = { ...this.structure };
  };

  moveRow = (structure, index, targetIndex) => {
    var sourceRow = _.cloneDeep(structure.rows[index]);
    var targetRow = _.cloneDeep(structure.rows[targetIndex]);

    structure.rows[targetIndex] = sourceRow;
    structure.rows[index] = targetRow;
  };

  loadForms = async () => {
    this.isLoading = true;
    this.name = undefined;
    this.forms = [];
    if (!ConfigStore.isOffline) {
      this.forms = (
        await this.graphQlService.get(
          `{ formBuilders { id name platform structure isActive allowStandalone hubOnly childTemplateId } }`
        )
      ).data.formBuilders;
      this.forms = this.forms.map(x => ({
        ...x,
        childTemplateId: x.childTemplateId ? x.childTemplateId.split(",") : [],
        platform: x.platform.split(",")
      }));
      localStorage.setItem(`forms`, JSON.stringify(this.forms));
    } else {
      this.forms = localStorage.getItem(`forms`) ? JSON.parse(localStorage.getItem(`forms`)) : [];
    }

    this.pendingForms = localStorage.getItem(`pendingForms`) ? JSON.parse(localStorage.getItem(`pendingForms`)) : [];
    this.isLoading = false;

    return this.forms;
  };

  loadChildTemplates = async parentId => {
    this.isLoading = true;
    var childTemplates = [];

    var templates = [];
    if (!ConfigStore.isOffline)
      templates = (
        await this.graphQlService.get(
          `{ formBuildersActive(platform: "web") { id name platform structure isActive hubOnly childTemplateId } }`
        )
      ).data.formBuildersActive;
    else templates = this.forms.filter(x => x.platform.includes("web"));
    if (!templates) templates = [];

    childTemplates.push({
      label: "Web",
      items: templates.map(x => ({ value: x.id, label: x.name })).filter(x => x.value !== parentId)
    });

    if (!ConfigStore.isOffline)
      templates = (
        await this.graphQlService.get(
          `{ formBuildersActive(platform: "mobile") { id name platform structure isActive hubOnly childTemplateId } }`
        )
      ).data.formBuildersActive;
    else templates = this.forms.filter(x => x.platform.includes("mobile"));
    if (!templates) templates = [];

    childTemplates.push({
      label: "Mobile",
      items: templates.map(x => ({ value: x.id, label: x.name })).filter(x => x.value !== parentId)
    });

    this.childTemplates = childTemplates;

    this.isLoading = false;
    return childTemplates;
  };

  loadForm = async id => {
    this.isLoading = true;
    var form = undefined;
    if (!ConfigStore.isOffline)
      form = (
        await this.graphQlService
          .get(`{ formBuilder(id: "${id}") { id name platform structure isActive allowStandalone hubOnly childTemplateId
      assetDefinition { id symbol value fields { id label subFields { id label } } } } }`)
      ).data.formBuilder;
    else {
      var forms = localStorage.getItem(`forms`) ? JSON.parse(localStorage.getItem(`forms`)) : [];
      form = forms.find(x => x.id === id);
    }

    this.name = form.name;
    this.platform = form.platform.split(",");
    this.isActive = form.isActive;
    this.allowStandalone = form.allowStandalone;
    this.hubOnly = form.hubOnly;
    this.templateId = form.id;
    this.childTemplateId = form.childTemplateId ? form.childTemplateId.split(",") : [];
    if (form.structure) this.structure = JSON.parse(form.structure);
    else this.structure = { rows: [{ cols: [{ span: 24 }] }] };
    this.assetDefinition = form.assetDefinition;

    await this.loadAssetDefinitionRelations();

    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  };

  loadAssetDefinitionRelations = async () => {
    if (!this.assetDefinition) return;

    const relations = (
      await this.graphQlService.get(
        `{ assetsDefinitionById(id: "${this.assetDefinition?.id}"){ relations { id value fields { id label } relatedAssetDefinition { id value fields { id label } } } } }`
      )
    ).data.assetsDefinitionById?.relations;

    this.assetDefinitionRelations = relations;
  };

  createForm = async history => {
    this.isLoading = true;
    var id = undefined;

    var payload = { name: this.name, platform: this.platform };
    if (!ConfigStore.isOffline)
      id = (
        await this.graphQlService.post(
          `mutation mutate($data: CreateFormTemplateCommand){ formBuilderCreate(data: $data) }`,
          { data: payload }
        )
      ).data.formBuilderCreate;
    else {
      id = uuidv4();
      payload["id"] = id;
      payload["isOffline"] = true;
      this.pendingForms.push({ action: "create", data: payload });
      this.forms.push(payload);
      localStorage.setItem(`forms`, JSON.stringify(this.forms));
      localStorage.setItem(`pendingForms`, JSON.stringify(this.pendingForms));
    }

    this.templateId = id;
    message.success(`Form has been created`);
    history.push(`/${localStorage.getItem(`projectName`)}/collect/forms/${this.templateId}`);
    this.isLoading = false;
  };

  updateForm = async () => {
    this.isLoading = true;

    var payload = {
      id: this.templateId,
      name: this.name,
      platform: this.platform.join(","),
      structure: JSON.stringify(this.structure),
      isActive: this.isActive,
      hubOnly: this.hubOnly ? this.hubOnly : false,
      allowStandalone: this.allowStandalone,
      childTemplateId: this.childTemplateId,
      assetDefinitionId: this.assetDefinition?.id
    };
    if (!ConfigStore.isOffline)
      await this.graphQlService.post(
        `mutation mutate($data: UpdateFormTemplateCommand){ formBuilderUpdate(data: $data) }`,
        { data: payload }
      );
    else {
      payload["isOffline"] = true;
      this.pendingForms.push({ action: "update", data: payload });
      this.forms[this.forms.findIndex(x => x.id === this.templateId)] = payload;

      localStorage.setItem(`forms`, JSON.stringify(this.forms));
      localStorage.setItem(`pendingForms`, JSON.stringify(this.pendingForms));
    }
    message.success(i18n.t("collect.updated"));
    this.isLoading = false;
  };

  deleteForm = async id => {
    this.isLoading = true;
    await this.graphQlService.post(
      `mutation mutate($data: DeleteFormTemplateCommand){ formBuilderDelete(data: $data) }`,
      { data: { id: id } }
    );
    message.success(`Form has been deleted!`);
    this.loadForms();
  };

  renderObject = (object, rowIndex = 0, colIndex = 0) => {
    switch (object.type) {
      case "InputMask":
        return <FormInputMask {...object} />;
      case "input":
        return this.currentPlatform === "mobile" ? <FormInputMobile {...object} /> : <FormInput {...object} />;
      case "numericInput":
        return <FormNumericInput {...object} />;
      case "textarea":
        return <FormTextArea {...object} />;
      case "select":
        return <FormSelect {...object} />;
      case "checkbox":
        return this.currentPlatform === "mobile" ? <FormCheckboxMobile {...object} /> : <FormCheckbox {...object} />;
      case "checkbox-group":
        return this.currentPlatform === "mobile" ? (
          <FormCheckboxGroupMobile {...object} />
        ) : (
          <FormCheckboxGroup {...object} />
        );
      case "radio":
        return <FormRadio {...object} />;
      case "file-upload":
        return this.currentPlatform === "mobile" ? (
          <FormFileUploadMobile {...object} />
        ) : (
          <FormFileUpload {...object} />
        );
      case "dxf-editor":
        return <CollectFormDxfEditor {...object} />;
      case "camera":
        return <FormCamera {...object} />;
      case "open-layers-map":
        return <FormOpenLayersMap {...object} />;
      case "datetime":
        return <FormDateTime {...object} />;
      case "switch":
        return <FormSwitch {...object} />;
      case "rate":
        return this.currentPlatform === "mobile" ? <FormStarRateMobile {...object} /> : <FormStarRate {...object} />;
      case "title":
        return <FormTitle {...object} />;
      case "form-sign":
        return <FormSign {...object} />;
      case "table":
        return <CollectFormTable {...object} />;
      case "collapse":
        return <CollectFormCollapse {...object} />;
      case "pdf-annotator-selected-claim":
        return <CollectFormPdfAnnotatorClaim {...object} />;
      case "parent-view":
        return <CollectFormParentView {...object} />;
      case "text":
        return <CollectFormText {...object} />;
      case "loop":
        return <CollectFormForEachComponent {...object} />;
      case "image-annotator":
        return <FormImageAnnotator {...object} />;
      default:
        return <FormBuilderDropZone parentId={object.parentId} rowIndex={rowIndex} colIndex={colIndex} />;
    }
  };

  syncPending = async () => {
    for (let index = 0; index < this.pendingForms.length; index++) {
      if (this.pendingForms[index].action === "create")
        await this.graphQlService.post(
          `mutation mutate($data: CreateFormTemplateCommand){ createFormBuilder(data: $data) }`,
          { data: this.pendingForms[index].data }
        );
      else
        await this.graphQlService.post(
          `mutation mutate($data: UpdateFormTemplateCommand){ updateFormBuilder(data: $data) }`,
          { data: this.pendingForms[index].data }
        );
      this.pendingForms = this.pendingForms.filter(x => x.id !== this.pendingForms[index].data.id);
    }

    localStorage.setItem(`pendingForms`, JSON.stringify([]));
    this.loadForms();
  };

  getAllFields = structure => {
    var fields = [];

    if (!structure) structure = this.structure;

    structure.rows.forEach(row => {
      row.cols.forEach(col => {
        if (col.type === "select" || col.type === "input" || col.type === "radio" || col.type === "textarea")
          fields.push({
            id: col.id,
            symbol: col.label,
            type: "string",
            value: "null",
            label: col.label,
            dbTableLocation: col.label
          });
        if (col.type === "switch")
          fields.push({
            id: col.id,
            symbol: col.label,
            type: "bool",
            value: false,
            label: col.label,
            dbTableLocation: col.label
          });

        if (col.items) {
          col.items
            .filter(x => x.structure)
            .forEach(item => {
              fields = [...fields, ...this.getAllFields(item.structure)];
            });
        }
      });
    });
    return fields;
  };

  getAllFieldsForDisable = structure => {
    var fields = [];
    if (!structure) structure = this.structure;

    structure.rows.forEach(row => {
      row.cols
        .filter(x => x.type)
        .forEach(col => {
          fields.push({ value: col.id, label: col.label });
          if (col.items) {
            col.items
              .filter(x => x.structure)
              .forEach(item => {
                fields = [...fields, ...this.getAllFieldsForDisable(item.structure)];
              });
          }
        });
    });
    return fields;
  };

  getAllElements = structure => {
    var fields = [];
    if (!structure) structure = this.structure;

    structure.rows.forEach(row => {
      row.cols
        .filter(x => x.type)
        .forEach(col => {
          fields.push(col);
          if (col.items) {
            col.items
              .filter(x => x.structure)
              .forEach(item => {
                fields = [...fields, ...this.getAllElements(item.structure)];
              });
          }
        });
    });
    return fields;
  };
}

export default new FormBuilderStore();
