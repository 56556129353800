import { ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import ConfigStore from "./ConfigStore";
import _, { cloneDeep } from "lodash";
import { message } from "antd";

import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { Observable } from "rxjs";
import { logout } from "./Users/Logout";

export default class GraphQlService {
  basePath = "";
  wsBasePath = "";
  accessToken = "";
  config = ConfigStore;

  constructor() {
    this.accessToken = localStorage.getItem("userId");
  }

  get = gqlString =>
    this.client().query({ query: this.stringToGql(gqlString), errorPolicy: "all", fetchPolicy: "no-cache" });

  post = (gqlString, variables) =>
    this.client().mutate({ mutation: this.stringToGql(gqlString), variables: variables, errorPolicy: "all" });

  displayErrors = errors => {
    if (!errors) return;
    errors.forEach(error => {
      if (error.extensions) {
        if (error.extensions.data && error.extensions.data.Code) {
          message.error(error.extensions.data.Code);
          error.extensions.data.Code === "NOT_PERMISSION" && (window.location.pathname = "/");
        }
        if (error.extensions.data && error.extensions.data.CODE) {
          message.error(error.extensions.data.CODE);
          error.extensions.data.CODE === "NOT_PERMISSION" && (window.location.pathname = "/");
        }
        if (error.extensions.code) {
          message.error(error.extensions.code);
          error.extensions.code === "NOT_PERMISSION" && (window.location.pathname = "/");
        }
      }
    });
    return;
  };

  client = () => {
    this.basePath = this.config.backendUrl + `graphql`;

    const httpLink = new HttpLink({
      uri: this.basePath,
      fetchOptions: "no-cors",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``)
      }
    });

    const auth = setContext((operation, context) => {
      const token = localStorage.getItem("jwtToken");
      if (token === null) {
        return {};
      } else {
        return {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``)
          }
        };
      }
    });

    const error = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (
          (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code === "authorization") ||
          graphQLErrors[0].message.includes(`You are not authorized to run this query`)
        ) {
          // logout();
          return;
        }
        if (
          graphQLErrors[0].extensions &&
          graphQLErrors[0].extensions.data &&
          graphQLErrors[0].extensions.data.CODE === "UNAUTHORIZED"
        ) {
          message.error(`You are not authorized to do this!`);
        }
      }
      if (networkError) {
        // logout();
      }
    });

    const ForwardExtensionsLink = new ApolloLink((operation, forward) =>
      forward(operation).map(response => {
        if (response.data) {
          if (response.extensions && response.extensions.totalEntries)
            response.data.totalEntries = response.extensions.totalEntries;
        }
        return response;
      })
    );

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([auth, error, ForwardExtensionsLink, httpLink])
    });
  };

  clientWs = () => {
    this.basePath = this.config.backendUrl + `graphql`;
    this.wsBasePath =
      this.config.backendUrlHost.replace("https://", "wss://").replace("http://", "wss://") + `/api/graphql`;

    const wsLink = new WebSocketLink({
      uri: this.wsBasePath,
      options: {
        reconnect: true,
        connectionParams: {
          Authorization: `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``)
        }
      }
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        // ({ query }) => {
        //   const definition = getMainDefinition(query);
        //
        //   return (
        //     definition.kind === 'OperationDefinition' &&
        //     definition.operation === 'subscription'
        //   );
        // },
        wsLink
      ])
    });
  };

  stringToGql = string => {
    return gql`
      ${string}
    `;
  };

  deserializeObject(object) {
    object = _.cloneDeep(object);
    if (!_.isEmpty(object["payload"])) object["payload"] = JSON.parse(object["payload"]);
    return object;
  }

  deserializeObjects(objects) {
    objects = _.cloneDeep(objects);
    objects.forEach((object, index) => {
      if (!_.isEmpty(object["payload"])) object["payload"] = JSON.parse(object["payload"]);
    });
    return objects;
  }
}
